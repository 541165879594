/* .m-about-container {

} */
.m-mission-statement-container {
    margin-top: 20px;
    width: 95%;
    margin: 0 auto 25px;
    color: white;
    font-size: 1.1rem;
}
.m-mission-statement-section {
    line-height: 1;
}

.m-bio-container {
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 12px 8px;
    width: 98%;
    margin: 10px auto;

}
.m-bio-character {
    color: white;
}
.m-bio-pic {
    border-radius: 5px;
    width: 93vw;
}
.m-bio-name {
    font-size: 1.5rem;
    margin: 7px 0 4px;
}
.m-bio-title {
    font-size: 1.2rem;
    font-weight: bold;
}
.m-bio-content {
    color: white;
    line-height: 1;
    font-size: 1.2rem;
    margin-top: 10px;
}