.d-auth-container {
    box-sizing: border-box;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 500px;
    max-width: 800px;
    border-radius: 4px;
    min-height: 500px;
    background-color: white;
    position: relative;
}

.d-auth-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}
.d-auth-footer {
    display: flex;
    flex-direction: row;
    margin: 20px auto 0;
    width: 100%;
    justify-content: center;
}
.d-auth-register-button {
    padding-left: 5px;
    font-weight: bold;
    cursor: pointer;
}
.d-auth-register-button:hover {
    color: var(--olive);
}
.d-auth-submit-button {
    margin: 30px auto 10px;
    background-color: var(--olive);
    color: white;
    cursor: pointer;
    padding: 10px 25px;
    width: 140px;
    text-align: center;
    border-radius: 4px;
}
.d-auth-body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.d-auth-submit-button:hover {
    background-color: lightgray;
}
.d-auth-errors {
    color: red;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    /* top: -20px; */
    top: -10px;
    width: 100%;
    left: 0;
    text-align: center;
    
}
.d-auth-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    margin: 4px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-auth-close-icon:hover {
    background-color: lightgray;
}
.d-auth-error-area {
    height: 50px;
    width: 360px;
    margin: 0 auto;
    word-wrap: break-word;
    overflow-wrap: break-word; 
    font-size: 16px;
    color: red;
    font-weight: bold;
    text-align: center;
}
.d-auth-profile-container {
    display: flex;
    flex-direction: column;
}
.d-auth-sign-out-button {
    background-color: var(--olive);
    color: white;
    font-size: 12px;
    display: flex;
    width: 100px;
    margin: 50px auto 0;
    padding: 5px 20px;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}
.d-auth-sign-out-button:hover {
    background-color: lightgray;
}
.d-auth-admin-header {
    font-size: 36px;
    margin-bottom: 33px;
    position: relative;
}

.d-admin-content-container {
    display: flex;
    flex-direction: column;
    /* background-color: red; */

    max-height: 500px;
    overflow-x: scroll;
}
.d-admin-content-home-button {
    margin: 10px auto;
    background-color: var(--olive);
    color: white;
    min-width: 70%; 
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    cursor: pointer;
    transition: 0.15s;
}
.d-admin-content-home-button:hover {
    background-color: lightgray;
    color: var(--olive);
}
.d-admin-back-to-home-button {
    position: absolute;
    font-size: 16px;
    top: 45px;
    left: 0;
    font-weight: bold;
    color: var(--olive);
    cursor: pointer;
    padding: 3px 8px;
    border-radius: 3px;
    background-color: lightgray;
    /* margin-bottom: 3px; */
}
.d-admin-back-to-home-button:hover {
    background-color: gray;
    color: white;
}

/*! PODCASTS  */

.d-admin-sub-page-container {
    max-height: 700px;
    max-width: 505px;
    overflow-x: scroll;
    z-index: 5;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.d-admin-sub-page-container::-webkit-scrollbar {
    display: none;
}

.d-admin-create-podcast-input-container {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 20px;
    margin-top: 10px;
}
.d-admin-create-podcast-input {
    /* max-width: 90%; */
    width: 100%;
    margin-bottom: 10px;
}
.d-a-c-p-audio-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 100%;
}
.d-a-c-p-remove-audio-button {
    padding: 2px 5px;
    color: black;
    font-weight: bold;
    border-radius: 4px;
    margin-top: 4px;
    cursor: pointer;
}
.d-a-c-p-remove-audio-button:hover {
    background-color: red;
    color: white;
}
.d-a-c-p-thumbnail-upload {
    width: 270px;
    height: 270px;
    background-color: rgba(211, 211, 211, 0.65);
    flex-shrink: 0;
    margin: 10px 0;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: var(--olive);
    position: relative;
}
.d-a-c-p-thumbnail-upload-icon {
    transition: 0.3s;
    padding: 10px;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 5;
}
.d-a-c-p-thumbnail-upload-icon:hover {
    background-color: rgba(255, 255, 255, 0.509);
}
.d-a-c-p-thumbnail-preview {
    position: absolute; 
    height: 270px;  
    width: 270px;
    opacity: 0.9;
    border-radius: 10px;
}

.d-a-c-p-section-divider {
    background-color: var(--olive);
    width: 70%;
    flex-shrink: 0;
    height: 6px;
    border-radius: 3px;
    margin: 50px auto;
}
.d-a-c-p-date-picker {
    font-size: 36px;
    margin: 10px 0 5px;
    outline: none;
    border: none;
    /* cursor: pointer; */
}
.d-a-c-p-link-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.d-a-c-p-link-header {
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    color: var(--olive);
    padding-left: 40px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
}
.d-a-c-p-submit-podcast-button {
    color: white;
    background-color: var(--olive);
    font-size: 30px;
    padding: 5px 8px;
    width: 200px;
    border-radius: 4px;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
}
.d-a-c-p-submit-podcast-button:hover {
    background-color: lightgray;
}
.d-a-c-p-errors-container {
    color: red;
    font-weight: bold;
    height: 20px;
    /* font-size: 16px; */
    margin-bottom: 10px;
}
.d-a-c-p-submission-container {
    font-size: 20px;
    color: var(--olive);
    font-weight: bold;
    margin-top: 55px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-a-c-p-submission-success {
    font-size: 36px;
    margin: 50px 0 30px;
    text-align: center;
}
.d-a-c-p-submission-success-exit {
    padding: 5px 3px;
    border-radius: 4px;
    transition: 0.3s;
    width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.d-a-c-p-submission-success-exit:hover {
    background-color: var(--olive);
    color: white;
}
.d-a-c-p-submission-success-icon {
    font-size: 100px;
    color: var(--olive);
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

/*! View all */

.d-a-c-p-loading {
    margin: 75px auto 0;
}
.d-a-c-all-podcasts-scroll {
    max-height: 400px;
    width: 100%;
    overflow-x: scroll;
    margin-top: 30px;
    box-sizing: border-box;
    padding-right: 15px;
}
.d-a-c-individual-podcast-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    min-height: 55px;
    box-sizing: border-box;
    /* padding: 3px; */
    border-radius: 10px;
    /* box-shadow: 3px; */
    border: 2px solid var(--olive-transparent);
}
.d-a-c-individual-podcast-row-left {
    box-sizing: border-box;
    padding: 8px 8px 3px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    word-wrap: break-word;
}
.d-a-c-individual-podcast-row-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 38%;
    padding-bottom: 5px;
}
.d-a-c-individual-podcast-row-left {
    width: 40%;
    padding-bottom: 8px;
}
.d-a-c-individual-podcast-row-right {
    width: 22%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    /* min-height: 55px; */
    font-size: 30px;
}
.d-a-c-individual-podcast-row-right-edit, .d-a-c-individual-podcast-row-right-delete {
    height: 50px;
    width: 50px;
    cursor: pointer;
    transition: 0.2s;
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: lightgray;
}
.d-a-c-individual-podcast-row-right-delete:hover, .d-a-c-individual-podcast-row-right-edit:hover {
    background-color: rgb(240, 240, 240);
}
.d-a-c-individual-podcast-row-right-delete:hover {
    color: var(--olive);
    color: red
}
.d-a-c-individual-podcast-row-right-edit:hover {
    color: var(--olive);
}
.d-a-c-individual-podcast-date {
    font-weight: bold;
    margin: 5px 0 10px;
    font-size: 16px;
}
/*! WARNING  */
.d-a-c-delete-podcast-warning {
    min-height: 200px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-a-c-delete-podcast-warning-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 8px;
    height: 60px;
    width: 100%;
    background-color: var(--olive);
    color: white;
    border-radius: 10px 10px 0 0;
}
.d-a-c-delete-podcast-warning-header-brand {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.d-a-c-delete-podcast-warning-header-close {
    /* font-size: 24px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}
.d-a-c-delete-podcast-warning-header-close:hover {
    color: black;
}
.d-a-c-delete-podcast-warning-body {
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 20px 15px;
    border: 3px solid var(--olive);
    border-radius: 0 0 10px 10px;
    font-size: 18px;
    font-weight: bold;
    color: var(--olive);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 400px;
    min-height: 170px;
    /* word-break: break-all; */
    text-align: center;
}
/* display: none */
.d-a-c-delete-podcast-warning-body-top {
    margin-bottom: 20px;
}
.d-a-c-delete-podcast-warning-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 20px;
    /* background-color: white; */
}
.d-a-c-delete-podcast-warning-delete, .d-a-c-delete-podcast-warning-cancel {
    padding: 8px 30px;
    border-radius: 4px;
    margin: 0 15px;
    cursor: pointer;
    transition: 0.3s;
}
.d-a-c-delete-podcast-warning-cancel {
    background-color: lightgray;
    color: black;
}
.d-a-c-delete-podcast-warning-cancel:hover {
    background-color: darkgray;
}
.d-a-c-delete-podcast-warning-delete {
    background-color: red;
    color: white;
}
.d-a-c-delete-podcast-warning-delete:hover {
    background-color: rgb(171, 0, 0);

}
.d-a-c-delete-podcast-feedback {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 20px;
}
.d-a-c-delete-podcast-feedback-text {
    margin-top: 40px;
}
/*! EDITING */
.d-a-c-p-edit-container {
    min-height: 120px;
    min-width: 300px;
    background-color: white;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    border: 4px solid var(--olive);
}
.d-a-c-p-edit-header {
    background-color: var(--olive);
    width: 100%;
    padding-left: 10px;
    padding-bottom: 5px;
    color: white;
    font-size: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-a-c-p-edit-exit {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s;
}
.d-a-c-p-edit-exit:hover {
    background-color: rgba(255, 255, 255, 0.509);
}
.d-a-c-p-edit-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
}
.d-a-c-p-edit-home-header {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1.8;
}
.d-a-c-p-edit-home-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 6px;
}
.d-a-c-p-edit-home-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 6px;
    background-color: var(--olive);
    color: white;
    text-align: center;
    min-width: 110px;
    padding: 10px 0;
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;
}
.d-a-c-p-edit-home-button:hover {
    background-color: lightgray;
    color: var(--olive);
}
.d-a-c-p-edit-home-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}
.d-a-c-p-edit-footer-save, .d-a-c-p-edit-footer-cancel {
    margin: 4px 10px;
    padding: 5px 8px;
    min-width: 100px;
    text-align: center;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}
.d-a-c-p-edit-footer-save {
    background-color: var(--olive);
}
.d-a-c-p-edit-footer-save:hover {
    background-color: lightgray;
    color: var(--olive);
}
.d-a-c-p-edit-footer-cancel {
    background-color: gray;
}
.d-a-c-p-edit-footer-cancel:hover {
    background-color: lightgray;
}
.d-a-c-p-audio-container {
    padding: 30px 0;
}
.d-a-c-p-edit-success-message {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: var(--olive);
    font-weight: bold;
    margin-top: 25px;
}
.d-a-c-p-edit-all-details-button {
    width: 200px;
    height: 35px;
    background-color: red;
    margin: 5px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-radius: 4px;
    background-color: var(--olive);
    color: white;
    cursor: pointer;
    transition: 0.2s;
}
.d-a-c-p-edit-all-details-button:hover {
    color: var(--olive);
    background-color: lightgray;
}
.d-a-c-p-edit-all-details-list {
    padding: 5px 0;
}
.d-a-c-p-date-picker-2 {
    font-size: 36px;
    margin: 20px;
    outline: none;
    border: none;
}