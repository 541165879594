.d-services-header-container {
    background-color: rgba(0,0,0,0.3);
    color: white;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 1.1rem;
}
.d-services-section {
    padding: 10px 0;
    line-height: 1;
}
.d-services-warning {
    border: 3px solid yellow;
    margin: 10px auto;
    width: 60%;
    background-color: black;
    border-radius: 5px;
    color: yellow;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 7px 0;
}
.d-services-service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.3);
    color: white;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 10px;
    margin-top: 10px;
}
.d-service-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    width: 100%;
}
.d-service-item-header {
    font-size: 1.7rem;
}
.d-service-item-header:last-of-type {
    font-size: 1rem;
}
.d-service-sub-item-container {
    margin: auto;
    font-size: 1.1rem;
    width: 40%;
}

.d-service-sub-item-container:last-of-type {
    font-size: 1rem;
}
.d-expansion-container {
    margin: 10px 0 20px;
}
.d-ex-panel-link {
    background-color: white;
    color: var(--olive);
    border-radius: 4px;
    width: 550px;
    text-align: center;
    padding: 8px 0;
    margin: 25px auto 10px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s;
}
.d-ex-panel-link:hover {
    background-color: rgba(255,255,255,0.85);
}
.dsfhgsdhjfjsj:hover {
    text-decoration: underline;
    color: rgba(255,255,255,0.85);
}
a {
    text-decoration: none;
}
.services-all-services-button {
    background-color: white;
    color: var(--olive);
    border-radius: 4px;
    width: 250px;
    text-align: center;
    padding: 8px 0;
    margin: 25px auto 40px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s;
    box-shadow: 0 4px 1px 5px rgb(0 0 0 / 20%), 0 2px 8px 10px rgb(0 0 0 / 15%);
}
.services-all-services-button:hover {
    background-color: rgba(255,255,255,0.85);
}