.d-blog-create-container {
    height: 500px;
    width: 750px;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    padding-top: 10px;
}
.d-blog-create-container::-webkit-scrollbar {
    display: none;
  }
.d-blog-create-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.d-blog-create-main-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
}
.d-blog-create-main-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.d-blog-thumbnail-upload {
    width: 120px;
    height: 120px;
    background-color: rgba(211, 211, 211, 0.65);
    flex-shrink: 0;
    margin: 10px 0;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: var(--olive);
    position: relative;
}
.d-blog-thumbnail-preview {
    position: absolute; 
    height: 120px;  
    width: 120px;
    opacity: 0.9;
    border-radius: 10px;
}

.d-blog-thumbnail-upload-icon {
    transition: 0.3s;
    padding: 10px;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 5;
}
.d-blog-thumbnail-upload-icon:hover {
    background-color: rgba(255, 255, 255, 0.509);
}
.d-blog-create-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.d-blog-create-add-section-icon {
    color: var(--olive);
    font-size: 35px;
    width: 250px;
    height: 40px;
    /* padding: 5px 0; */
    /* background-color: red; */
    border-radius: 4px;
    border: 1.5px solid #cacaca;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 30px;
}
.d-blog-create-add-section-icon:hover {
    border-color: var(--olive);
}
.d-blog-create-showing-sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 70px;
}
.d-blog-panel-button {
    border: 1.5px solid #cacaca;
    padding: 0 10px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--olive);
    cursor: pointer;
}
.d-blog-panel-button:hover {
    border-color: var(--olive);
}
.d-blog-panel-header {
    font-weight: bold;
    font-size: 20px;
}
.d-blog-panel-body {
    font-size: 14px;
}
.d-blog-panel-bullet {
    flex-direction: row;
}
.d-blog-panel-emphasis {
    font-size: 24px;
    letter-spacing: 1.3px;
}
.d-blog-panel-cancel {
    background-color: gray;
    color: white;
    border-color: gray;
}
.d-blog-create-content-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.d-blog-list-item-create-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    /* position: relative; */
}
.d-blog-delete-content-button-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
}
.d-blog-delete-content-button {
    color: rgba(255, 0, 0, 0.493);
    cursor: pointer;
    margin-left: 20px;
    height: 100%;
    transition: 0.3s;
}
.d-blog-delete-content-button:hover {
    color: red;
}
.d-blog-upload-image-content-item {
    font-size: 50px;
    color: var(--olive);
    transition: 0.3s;
    padding: 10px;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 5;
    margin-right: 10px;
}
.d-blog-upload-image-content-item:hover {
    background-color: rgba(0, 0, 0, 0.2);
}
.d-blog-image-container {
    position: relative; 
    height: 60px;  
    width: 60px !important;
    flex-shrink: 0;
    opacity: 0.9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 20px; */
}
.d-blog-image-preview {
    /* position: absolute;  */
    height: 60px;  
    width: 60px;
    opacity: 0.9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* d-blog-thumbnail-preview list-item-create-row */

.d-blog-control-panel-container {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.d-blog-submit-button-container {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #cacaca;
    border-radius: 4px;
    color: var(--olive);
    font-weight: bold;
    font-size: 20px;
}
.d-blog-submit-button-hover:hover {
    border-color: var(--olive);   
}
.d-blog-close-error-button {
    margin-top: 3px;
    padding: 6px 20px;
    cursor: pointer;
    border-radius: 4px;
}
.d-blog-close-error-button:hover {
    background-color: lightgray;
}


.d-blog-create-author-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.d-blog-create-author-option {
    width: 200px;
    height: 40px;
    /* background-color: red; */
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--olive);
    border-radius: 4px;
    color: var(--olive);
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s;
}
.d-blog-create-author-option:hover {
    background-color: var(--olive-transparent) !important;
    color: white !important;
}







/*! Official Blog  */

.d-blog-preview-container {
    border: 2px solid white;
    border-radius: 4px;
    height: 90vh;
    width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: scroll;
    position: relative;
}
.d-blog-preview-container::-webkit-scrollbar-thumb {
    background: white;
}
.d-blog-preview-container::-webkit-scrollbar {
    width: 2px;
}
.d-blog-preview-container::-webkit-scrollbar-thumb:hover {
    background: white;
}
.d-blog-preview-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    height: 70px;
    margin-bottom: 20px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: white;
    background-color: var(--olive-transparent);
    top: 0; left: 0; right: 0;
}
.d-blog-preview-header-text {
    padding: 5px 0 5px 8px;
    color: white;
    font-size: 30px;
}
.d-blog-preview-header-close {
    padding: 3px;
    margin: 3px;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.3s;
}
.d-blog-preview-header-close:hover {
    background-color: rgba(255,255,255,0.2);
}
.d-blog-preview-divider {
    background-color: white;
    width: 90%;
    height: 1px;
    margin: 10px auto;
    flex-shrink: 0;
}
.d-blog-details-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    margin: 0 auto 40px;
}
/*? Header  */
.d-blog-thumbnail {
    width: 650px;
    border-radius: 2px;
}
.d-blog-title {
    text-align: left;
    width: 100%;
    font-size: 40px;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 10px;
}
.d-blog-subtitle {
    text-align: left;
    width: 100%;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding-left: 20px;
}
.d-blog-author {
    text-align: right;
    width: 100%;
    color: white;
    font-size: 20px;
    margin-bottom: 5px;
}
.d-blog-timestamp {
    text-align: right;
    width: 100%;
    color: white;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 10px;
}
.d-blog-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
}
/*? CONTENT  */
.d-blog-header {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px 0 25px;
    font-size: 40px;
    font-weight: bold;
    margin: 10px 0;
}
.d-blog-body {
    width: 85%;
    margin: 5px auto 10px;
    font-size: 20px;
}
.d-blog-list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-left: 100px;
    margin: 2px 0;
    font-size: 20px;
}
.d-blog-emphasis {
    font-size: 40px;
    letter-spacing: 2px;
    width: 85%;
    flex-wrap: wrap;
    text-align: center;
    margin: 5px auto;
}
.d-blog-picture-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0 55px;
}
.d-blog-picture {
    width: 90%;
    margin: 0 auto;
}
.d-blog-picture-caption {
    margin-top: 5px;
    letter-spacing: 0.9px;
    font-size: 20px;
}
.d-blog-youtube {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-blog-external-link {
    width: 60%;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 30px auto;
    font-size: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
}
.d-blog-external-link:hover {
    text-decoration: underline;
}
.d-blog-preview-submit-header, .d-blog-preview-submit-status {
    color: white;
    margin: 0 auto;
    font-size: 36px;
}
.d-blog-preview-submit-header {
    margin-top: 100px;
}
.d-blog-preview-submit-status {
    margin-top: 25px;
}
.d-blog-create-blog-final-button {
    /* margin: 10px auto; */
    /* position: fixed; */
    /* top: 10px; */
    /* margin: 0 auto; */
    color: white;
    font-size: 30px;
    border-radius: 4px;
    border: 2px solid white;
    padding: 2px 10px;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 20px;
}
.d-blog-create-blog-final-button:hover {
    background-color: rgba(255,255,255,0.15);
}



/*? FRONT END */

.d-blog-header {
    color: white;
    margin: 10px auto;
    width: 100%;
    text-align: center;
}
.d-blog-list-container {
    height: 420px;
    margin-bottom: 30px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.d-blog-list-container::-webkit-scrollbar {
    display: none;
}

.d-blog-list-item-guest {
    border: 2px solid white;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    transition: 0.3s;
    cursor: pointer;
    color: white;
    position: relative;
}
.d-blog-list-item-guest:hover {
    background-color: rgba(0, 0, 0, 0.5);
}
.d-blog-list-item-main {

}
.d-blog-list-item-details {
    position: absolute;
    top: 5px; right: 5px;
    font-style: italic;
    opacity: 60%;
}
.d-blog-list-item-main-title {
    font-size: 32px;
}
.d-blog-list-item-main-subtitle {
    font-size: 16px;
}
.d-blog-list-item-main-description {

}
.d-guest-blog-container {
    margin-top: 50px;
}