/* .m-services-container {

} */
.m-services-header {
    font-size: 1.6rem;
    color: white;
    margin: 10px 0;
}
.m-services-warning {
    border: 3px solid yellow;
    margin: 10px auto;
    width: 95%;
    background-color: black;
    border-radius: 5px;
    color: yellow;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 7px 0;
}
.m-services-header-container {
    color: white;
    font-size: 1.3rem;
    margin-bottom: 20px;
}
.m-services-section {
    margin: 5px 0;
    width: 95%;
    line-height: 1;
}
.m-services-backdrops-container {
    width: 100%;
}
.m-services-backdrop-button {
    width: 90%;
    margin: 5px auto 20px;
    /* font-size: 24px; */
    font-size: 1.3rem;
    background-color: white;
    color: var(--olive);
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 4px;
    font-weight: bold;
    /* box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2), 0 2px 8px 8px rgba(0, 0, 0, 0.15); */
    box-shadow: 0 4px 1px 5px rgb(0 0 0 / 20%), 0 2px 8px 10px rgb(0 0 0 / 15%);
}
.m-services-backdrop-header {
    background-color: var(--olive);
    box-sizing: border-box;
    padding: 12px 12px 10px;
    line-height: 1.1;
    width: 100%; 
    font-size: 24px;
    border-radius: 8px 8px 0 0;
    color: white;
}
.m-services-backdrop-body-container {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px;
    font-size: 1.2rem;
    color: var(--olive);
    line-height: 1;
}
.m-services-backdrop-body-item {
    margin-bottom: 10px;
}
.m-services-button-link {
    font-size: 1.4rem;
    color: white;
    background-color: var(--olive);
    padding: 5px 25px;
    border-radius: 4px;
    box-shadow: 2px 8px 5px rgb(0 0 0 / 15%);
    margin: 25px 0 10px;
    z-index: 105;
}
.m-services-close-button {
    margin-bottom: 20px;
    padding: 12px 20px;
    color: var(--olive);
    text-decoration: underline;
    z-index: 105;
}