.desktop-landing-animation-container {
    margin: 75px 0 20px;
    width: 900px;
    min-height: 350px;
    /* border: 2px solid rgb(204, 204, 204); */
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .desktop-landing-animation-container:hover .animation-image-container { */
    /* animation-play-state: paused; */
/* } */
.animation-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    animation-iteration-count: infinite;
    animation-duration: 22s;
    animation-delay: 10s;
    text-shadow: 3px 3px 8px black;

}
.animation-image {
    border-radius: 5px;
    box-shadow: 0 1px 6px 6px rgba(0, 0, 0, 0.2), 0 2px 4px 4px rgba(0, 0, 0, 0.15);
    height: 270px;
    width: 202px;
    margin: 6px;
}
/*! TOTAL WIDTH PER CARD: 212px  */

.animation-image-name {
    margin: 8px 0 5px;
    font-weight: 800;
    font-size: 1.2rem;
}
.animation-image-date {
    font-style: italic;
    font-size: 0.9rem;
}

.jacob { animation-name: jacob-animation; }
.sam { animation-name: sam-animation; }
.philip { animation-name: philip-animation; }
.debra { animation-name: debra-animation; }

@keyframes jacob-animation {
    0% { opacity: 1; }
    2% { transform: translateX(0); }
    8% { transform: translateX(212px);}
    30% { opacity: 1; }
    33% { opacity: 0; transform: translateX(212px);}
    81% { opacity: 0; transform: translateX(0); }
    83% { opacity: 1; }
    100% { }
}
@keyframes sam-animation {
    0% { opacity: 1; }
    3% { opacity: 0; }
    81% { opacity: 0; }
    83% { opacity: 1; transform: translateX(0); }
    100% { }
}
@keyframes philip-animation {
    0% { opacity: 1; }
    3% { opacity: 0; }
    81% { opacity: 0; }
    83% { opacity: 1; }
    100% {}
}
@keyframes debra-animation {
    0% { opacity: 1; }
    2% { transform: translateX(0); }
    8% { transform: translateX(-212px); }
    33% { transform: translateX(-212px); }
    40% { transform: translateX(-318px); }
    75% { transform: translateX(-318px); }
    81% { transform: translateX(0); }
    100% { }
}
/*? STEPS - 20s */
/* 0% Delay - have all 4 on screen */
/* 10% Fade out Sam and Philip */
/* 20% Move Jacob and Debra to middle */
/* 30% Delay - have 2 on screen */
/* 40% Fade out Jacob */
/* 50% Move Debra to middle */
/* 60% Delay Have Debra only on screen */
/* 70% Move Debra back to original place */
/* 80% Fade in others */
/* 100% 5s Delay at end */