.d-podcasts-header {
    margin: 50px auto 20px;
    text-align: center;
    color: white;
    font-size: 70px;
    width: 75%;
}
.d-podcasts-loading-container {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 135px 0 150px;
}
.d-podcasts-loading-feedback {
    font-size: 24px;
    margin-bottom: 10px;
}
.d-podcast-list-fade {
    position: relative;
    width: 100%;
}
.d-postcast-list-container {
    height: 350px;
    margin-bottom: 30px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}
.d-postcast-list-container::-webkit-scrollbar {
    display: none;
}
.d-podcast-list-item {
    border: 2px solid white;
    /* background-color: rgba(255, 255, 255, 0.3); */
    /* min-height: 150px; */
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    transition: 0.3s;
    cursor: pointer;
    color: white;
    position: relative;
}
.d-podcast-list-item:hover {
    background-color: rgba(0, 0, 0, 0.5);
}
.d-podcast-list-item-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.d-podcast-list-item-details {
    position: absolute;
    top: 5px; right: 5px;
    font-style: italic;
    opacity: 60%;
}
.d-podcast-list-item-main-title {
    font-size: 36px;
    line-height: 0.9;
    font-weight: bold;
    width: 100%;
}
.d-podcast-list-item-main-description {
    font-size: 18px;
    width: 95%;
}
.d-podcast-most-recent-label {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    padding-top: 20px;
    letter-spacing: 1px;
    width: 100%;
    text-align: center;
}
.d-postcast-list-container-top-fade {
    position: absolute;
    top: 0;
    width: 100%;
    height: 8px;
    /* background-color: red; */
    z-index: 100;
    background-image: linear-gradient(to bottom, var(--olive) , transparent);
}
.d-postcast-list-container-bottom-fade {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
    /* background-color: red; */
    background-image: linear-gradient(to top, var(--olive) , transparent);

    z-index: 100;
}
.d-podcast-player-container {
    background-color: white;
    min-height: 200px;
    border-radius: 4px;
    margin-bottom: 100px;
    box-sizing: border-box;
    padding: 30px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}
.d-podcast-player-info-container {
    color: var(--olive);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.d-podcast-player-info-header {
    font-size: 36px;
    font-weight: bold;
    align-self: flex-start;
    margin-bottom: 10px;
    margin-top: 5px;
    line-height: 0.95;
}
.d-podcast-player-info-body {
    width: 95%;
    margin-bottom: 20px;
    font-weight: bold;
}
.d-podcast-icon-container {
    color: var(--olive);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
}
.d-podcast-icon-container:hover {
    /* background-color: rgba(88, 83, 58, 0.3); */
    background-color: rgb(218, 218, 218);

}
.d-podcast-player-date {
    position: absolute;
    top: -23px; right: -22px;
    opacity: 40%;
    font-style: italic;
}
.rhap_container {
    box-shadow: none !important;
}
.rhap_time {
    color: var(--olive) !important;
    font-weight: bold;
}
.rhap_progress-indicator, .rhap_volume-bar, .rhap_volume-indicator {
    background: var(--olive) !important;
    opacity: 1 !important;
}
.rhap_download-progress {
    background-color: var(--olive) !important;
}
.rhap_progress-bar-show-download {
    background-color: rgba(0,0,0,0.1) !important;
}
.rhap_main-controls-button, .rhap_volume-button, .rhap_repeat-button {
    color: var(--olive) !important;
}
.d-podcast-extra-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0 0;
    /* margin-top: 20px; */
}
.d-podcast-guest-name-container {
    /* align-self: flex-start; */
    color: var(--olive);
}
.d-podcast-link-text {
    color: var(--olive);
    font-weight: bold;
    transition: 0.8s;
}
.d-podcast-link-text:hover {
    text-decoration: underline;
}
.d-podcast-guest-name-decoration {
    font-weight: bold;
}
.d-podcasts-return-to-all {
    position: absolute;
    bottom: -70px;
    color: white;
    padding: 15px 30px;
    cursor: pointer;
}
.d-podcasts-return-to-all:hover {
    text-decoration: underline;
}
.d-podcast-no-podcast-message {
    text-align: center;
    font-weight: bold;
    color: white;
    margin: 150px 0 20px;
    display: flex;
    flex-direction: column;
}
.d-podcasts-return {
    margin: 20px auto 300px;
    text-align: center;
    color: white;
}
.d-podcasts-return:hover {
    text-decoration: underline;
}

.d-podcast-thumbnail {
    height: 300px;
    position: absolute;
    margin: auto;
    z-index: -1900;
}


/* .d-podcast-player-container */

/*! PREVIEW  */
.d-podcasts-preview-header, .d-podcasts-preview-subheader, .d-podcasts-preview-subheader-bottom {
    text-align: center;
    color: white;
}
.d-podcasts-preview-header {
    font-size: 24px;
    margin-top: 70px;
    margin-bottom: 10px;
}
.d-podcasts-preview-subheader {
    margin-bottom: 150px;
    font-size: 24px;
    margin-top: -20px;
    letter-spacing: 0.9px;
}
.d-podcasts-preview-subheader-bottom {
    margin-bottom: 80px;
}