.desktop-navbar {
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--width);
    position: fixed;
    top: 0;

}

.nav-items-container {
    height: 100%;
    display: flex;
    flex-direction: row;

}
.nav-item {
    text-decoration: none;
    font-size: 20px;
    padding: 6px 23px;
    border: 2px solid white;
    margin: 0 3px;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    transition: 0.25s;
}
.nav-item:hover {
    background-color: rgba(255,255,255,0.3);
}
.desktop-navbar-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 0.5vh;
    width: 100%;
    padding-top: 10px;
    /* min-height: 18vh; */
    background-color: var(--olive-transparent);
    position: relative;
}
.desktop-navbar-top-row {
    display: flex;
    align-self: center;
    flex-direction: row;
    width: 780px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.desktop-navbar-contact-items {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
    /* max-width: 35%; */
    text-align: right;
    line-height: 0.95;
    word-wrap: normal;
    margin-right: 5px;
}
.desktop-navbar-bottom {
    height: 1vh;
    background-image: linear-gradient(to bottom, var(--olive-transparent) 0%, var(--olive-transparent) 50%, rgba(255,255,2550,0) 100%);
    width: 100%;
}

.nav-social-media-container {
    width: 60px;
    box-sizing: border-box;
    height: 700px;
    position: absolute;
    right: 0;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-social-media-link {
    color: white;
    padding: 7px;
    transition: 0.3s;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    cursor: pointer;
}
.d-social-media-link:hover {
    background-color: rgba(0,0,0,0.3);
}