.d-contact-container {
    background-color: rgba(0,0,0,0.3);
    color: white;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 10px;
    min-height: 100px;
    margin: 35px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-new-contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.d-new-contact-header {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 3px 0;
}
.d-new-contact-link {
    color: white;
    transition: 0.2s;
}
.d-new-contact-link:hover {
    color: rgb(201, 201, 201);
}
.d-contact-section-container {
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.d-contact-item-container {
    margin: 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    text-decoration: none;
    transition: 1s;
    padding: 0 10px;
}
.d-contact-item-container:hover {
    text-decoration: underline;
}
.d-contact-item-label {
    font-size: 1rem;
    margin-bottom: 8px;
}
.d-contact-item-text {
    margin-left: 5px;
    font-size: 1.3rem;
}
.d-contact-padding {
    width: 1px;
    height: 130px;
}
.d-contact-office-hours-container {
    background-color: rgba(0,0,0,0.3);
    color: white;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.d-office-hours-header {
    font-size: 1.6rem;
    margin: 10px 0 15px;
}
.d-office-hour-container {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    padding-bottom: 10px;
}