.desktop-home {
    width: 100%;
    min-height: 1800px;
    justify-content: flex-start;
}
.landing-image {
    z-index: 8;
    margin-top: 22vh;
    margin-bottom: 20px;
    height: 520px;
}
.home-image {
    border-radius: 7px;
    box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.2), 0 2px 8px 8px rgba(0, 0, 0, 0.15);
}
.desktop-brand-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--width);
    flex-direction: row;
}
.initial-brand {
    color: white;
    font-size: 70px;
    line-height: 1;
    z-index: 11;
    margin: 8px 0 6px 5px;
    text-shadow: 3px 3px 8px black;
}
.desktop-brand-container .brand-contact-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    font-size: 18px;
}
.desktop-brand-container .brand-contact-details div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/*? Nav Buttons */

.desktop-nav-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    z-index: 9;
    /* height: 22vh; */
    /* border: 1px solid red; */
    /* margin-top: 70px; */
    /* background-color: var(--olive); */
}



.desktop-home-landing-caption {
    margin-bottom: 45px;
    font-size: 35px;
    color: white;
}
.desktop-second-image {
    width: 645px;
}
.desktop-landing-third-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 85px 0 50px;
}
.desktop-landing-third-image-image {
    width: 330px
}
.third-image-content {
    font-size: 18px;
    width: 370px;
    margin-left: 35px;
    /* color: rgb(204, 204, 204); */
    color: white;
    box-sizing: border-box;
    padding: 5px;
    min-height: 200px;
    flex: 1;
}
.third-image-body {
    text-align: center;
    margin-bottom: 8px;
}
.third-image-author {
    text-align: center;
    margin-bottom: 20px;
}
.third-image-qualifications {
    padding-left: 50px;
}
.desktop-landing-footer {
    font-size: 20px;
    margin: 10px 0 65px;
    color: rgb(204, 204, 204);
}

