
.d-about-style {
    background-color: rgba(0,0,0,0.3);
    color: white;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px;
}
.desktop-mission-statement-container {
    font-size: 1.2rem;
    margin-bottom: 10px;
    line-height: 1;
}
/* .d-mission-statement-section {
     */
/* } */

.d-bios-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}
.d-bio-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    width: 100%;
}
.d-bio-character {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
}
.d-bio-pic-placeholder {
    height: 300px;
    width: 240px;
    border-radius: 10px;
    /* background-color: rgba(0,0,0,0.8); */
    margin-bottom: 8px;
}
.d-bio-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
}
.d-bio-title {
    font-style: italic;
}
.d-bio-content {
    box-sizing: border-box;
    padding: 0 15px;
    /* width: 50%; */
    line-height: 1.3;
}