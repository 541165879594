@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');

body, * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
:root {
    --primary: rgb(61, 68, 53);
    --secondary: rgb(194, 200, 204);
    --background: rgb(61, 68, 53);
    --background-light: rgb(194, 200, 204);
    --font: rgb(194, 200, 204);
    --olive: rgb(88, 83, 58);
    --olive-transparent: rgba(88, 83, 58, 0.9);
    --olive-transparent-light: rgba(88, 83, 58, 0.4);
    --width: 1000px;
}
body {
    background-color: var(--olive);
}
.brand-text {
    font-size: 24;
    font-weight: 800;
    text-transform: uppercase;
    color: var(--font)
}
.large-text {
    font-size: 24;
    font-weight: 800;
    text-transform: uppercase;
    color: var(--font)
}
.small-text {
    font-size: 12;
    letter-spacing: 2px;
    color: var(--font)
}
.caption-text {
    font-size: 8;
    font-weight: 800;
    color: var(--font)   
}
.fancy-font {
    font-family: 'Square Peg';
    font-weight: 800;
}
.appflex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}