/* .m-contact-container {

} */
.m-contact-section-container {
margin-top: 40px;
}
.m-new-contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 70px 0 150px;
}
.m-new-contact-header {
    font-size: 15px;
    color: white;
    margin: 5px 0;
}
.m-new-contact-link {
    color: white;
    text-decoration: none;
    transition: 0.2s;
    font-weight: bold;
}
.m-contact-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: white;
    margin: 10px 0;
    background-color: rgba(0,0,0,0.3);
    padding: 8px;
    border-radius: 5px;
}
.m-contact-item-text {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
}
.m-contact-item-label {
    font-size: 1.4rem;
    font-weight: bold;
}
.m-contact-office-hours-container {
    width: 100%;
    margin-bottom: 155px;
}
.m-office-hours-header {
    font-size: 24px;
    color: white;
    font-weight: bold;
    margin: 10px 0 30px;
}
.m-office-hours-container {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    color: white;
}
/* .m-office-hours-day {

} */