.d-notifications-container {
    background-color: rgba(255, 255, 255, 0.801);
    position: fixed;
    /* bottom: 20px; */
    /* top: 50px; */
    left: 5px;
    top: 200px;
    z-index: 25;
    box-sizing: border-box;
    padding: 10px;
    border: 4px solid var(--olive);
    max-width: 250px;
    border-radius: 10px;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    transition: 0.4s;
}