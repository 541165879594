@import url('./Style/Theme.css');
@import url('./Style/Nav/index.css');
@import url('./Style/Home/index.css');
@import url('./Style/Fade/index.css');
@import url('./Style/About/index.css');
@import url('./Style/Services/index.css');
@import url('./Style/Contact/index.css');
@import url('./Style/Podcast/index.css');
@import url('./Style/Auth/index.css');
@import url('./Style/Notifications/index.css');
@import url('./Style/Blog/index.css');

.desktop-section-container {
    padding-top: 160px;
    width: 700px;
}
.mobile-app {
    min-height: 100vh;
    background-color: var(--olive);
}
.mobile-app::-webkit-scrollbar {
    display: none;
}
.mobile-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
