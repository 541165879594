.m-nav-container {
    position: fixed;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;

}
.m-mobile-nav-fade {
    height: 8px;
    width: 100vw;
    background-image: linear-gradient(to top, white 0%, rgba(255,255,255,0) 100%);
}