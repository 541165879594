.m-home-container {
    /* background-color: var(--olive); */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.m-home-container::-webkit-scrollbar {
    display: none;
}
.m-brand-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.m-brand-text {
    text-align: center;
    color: white;
    font-size: 50px;
    line-height: 1;
    z-index: 11;
    letter-spacing: 1.5px;
    margin: 20px 0 10px;
    text-shadow: 3px 3px 8px black;
}
.m-brand-sub-text {
    font-weight: bold;
    letter-spacing: 1px; 
    font-size: 20px;
    color: white;
}
.m-landing-image-container {
    margin-top: 40px;
}
.m-landing-image {
    width: 95vw;
}
.m-landing-image-caption {
    margin: 20px 0;
    font-size: 32px;
    text-align: center;
    line-height: 0.85;
    letter-spacing: 1.5px;
    width: 95%;
    color: white;
}
.m-landing-image-caption-lol {
    margin: 5px 0;
    font-size: 12px;
    text-align: center;
    line-height: 0.85;
    letter-spacing: 1.5px;
    width: 95%;
    color: white;
}
.m-home-third-image-container {
    margin: 45px 0 0;
    width: 100vw;
    margin-bottom: 10vh;
}
.m-home-third-image-container::-webkit-scrollbar {
    display: none;
}
.m-landing-third-image  {
    width: 50vw;
    margin-left: 2.5vw;
}
.m-home-third-middle-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
}
.m-home-caption {
    color: white;
    /* font-size: 1.1rem; */
}
/* .m-home-third-top-caption {
    margin-bottom: 5px;
} */
.m-home-middle-caption{
    width: 43vw;
    margin-right: 2.5vw; 
    line-height: 1.3;  
    text-align: right;
}
.m-home-third-image-third-row {
    align-self: flex-end;
    margin: 5px 30px 10px 0;
}
.m-home-third-image-fourth-row {
    width: 90vw;
}
.m-home-third-image-fourth-row-item {
    margin-left: 40px;
}

.m-home-family-history-container {
    margin-bottom: 10px;
    /* width: 95vw; */
    padding: 5px 10px;
    border-radius: 5px;
}
.m-home-family-header {
    font-size: 60px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.m-history-content-container {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.m-home-history-image {
    width: 70vw;
}
.m-home-family-history-container {
    background-color: rgba(0,0,0,0.3);
}
.m-history-name {
    color: white;
    font-size: 2rem;
    padding-top: 12px;
}
.m-history-date {
    color: white;
    font-size: 1.2rem;
    padding: 5px 0 7px;
}
.m-home-history {
    margin-bottom: 30px;
}
.m-home-final-caption-container {
    margin-bottom: 25px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-size: 1.5rem;
    width: 95%;
}
.m-mobile-footer {
    color: rgb(204, 204, 204);
    font-size: 20px;
    margin: 10px auto 16vh;
    text-align: center;

}